import { Layout, AppBar } from 'react-admin';
import React from 'react';
import WorkIcon from '@mui/icons-material/Work';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Box, IconButton, Typography } from '@mui/material';
import { processSubscriptions, training } from 'utils/api';

const MyAppBar = () => (
  <AppBar color="primary">
    <Typography
      variant="h6"
      color="inherit"
      // className={classes.title}
      id="react-admin-title"
    />
    <Box component="span" flex={1} />
    <IconButton
      onClick={async () => {
        if (!window.confirm('Train neural network?')) return;
        try {
          await training();
          window.alert('Training started');
        } catch (e) {
          window.alert('Training error');
        }
      }}
    >
      <WorkIcon />
    </IconButton>
    <IconButton
      onClick={async () => {
        if (!window.confirm('processSubscriptions?')) return;
        try {
          await processSubscriptions();
          window.alert('Started');
        } catch (e) {
          window.alert('Error');
        }
      }}
    >
      <SyncAltIcon />
    </IconButton>
  </AppBar>
);

export const CustomLayout = (props) => <Layout {...props} appBar={MyAppBar} />;
