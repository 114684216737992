import React from 'react';

import {
  List,
  Datagrid,
  ShowButton,
  EditButton,
  UrlField,
  BooleanField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const ItemList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <DateFieldUnixtime source="timestampFinished" label="Finished at" showTime />
      <BooleanField source="enabled" />
      <UrlField source="host" target="_blank" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default ItemList;
